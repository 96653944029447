@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
*{outline:none;}
html, body {height:100%; line-height:1.3; background:#f9f9f9;}
body,.wrapper{min-height:100vh; height:100%;}
button:focus{outline:none;}
body{color:#232323; font-family:'Open Sans', sans-serif; font-weight:400; font-size:14px;}
a{color:#4285F4;text-decoration:none;}
ul{padding:0px;margin:0px; list-style-position:inside}
img{max-width:100%;}
strong{font-weight:600;}
label{font-weight:400;margin-bottom:0.4rem;}
th{font-weight:500;}
:disabled {cursor:not-allowed;}
.whitespace-preline{white-space:pre-line;}
.table-sm th, .table-sm td {padding: 0.5rem;}
.table-nonfluid{width:auto!important}
.tablePad0>tbody>tr>td,.tablePad0>tbody>tr>th,.tablePad0>tfoot>tr>td,.tablePad0>tfoot>tr>th,.tablePad0>thead>tr>td,.tablePad0>thead>tr>th{padding:0}
.form-control::-webkit-input-placeholder{font-size:14px;color:#a2a2a2;opacity:1}.form-control::-moz-placeholder{font-size:14px;color:#a2a2a2;opacity:1}.form-control:-ms-input-placeholder{font-size:14px;color:#a2a2a2;opacity:1}.form-control:-ms-input-placeholder{font-size:14px;color:#a2a2a2;opacity:1}.form-control:placeholder{font-size:14px;color:#a2a2a2;opacity:1}
.form-select{background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%414141'><polygon points='0,0 100,0 50,50'/></svg>"); background-size:8px 8px;padding-right:1.25rem;}
.custom-control{min-height:1rem;}
.custom-control-label{cursor:pointer;}
.custom-control-label:before,.custom-control-label:after{top:0px;}
.tooltip{ line-height:1.2;}
.sticky-top{z-index:10;}
.modal-xxl{max-width:98%;}
.title{font-size:1.8rem; font-weight:500; margin:0 0 15px;}
.nametitle{font-size:17px; line-height:22px; margin:0 0 5px;font-weight:600;}
.nametitle a:hover {text-decoration:none; color:#32a953;}
.xsSmall{font-size:65%;}
.fn10{font-size:10px;}
.fn11{font-size:11px;}
.fn12{font-size:12px;}
.fn13{font-size:13px;}
.fn14{font-size:14px !important;}
.fn15{font-size:15px !important;}
.fn16{font-size:16px;}
.fn17{font-size:17px;}
.fn18{font-size:18px;}
.fn20{font-size:20px;}
.fn21{font-size:21px; line-height:27px;}
.fn24{font-size:24px !important;}
.fn33{font-size:33px !important;}
.lw-100 label{width:100%;}
.li17 {line-height:17px;}
.li20 {line-height:20px;}
.li21 {line-height: 21px;}
.li24 {line-height: 24px;}
.h-0{height:0px;}
.w225{min-width:225px;}
.radius-0 {border-radius:0px !important;}
.radius-left-0 {border-top-left-radius:0px !important;border-bottom-left-radius:0px !important;}
.radius-right-0 {border-top-right-radius:0px !important;border-bottom-right-radius:0px !important;}
.rounded-lg{border-radius:0.6rem !important}
.rounded-30{border-radius:30px !important;}
.rounded-top-left-1 {border-top-left-radius:0.375rem;}
.lead{font-size:1.15rem;}
.zIndex1{z-index:111;}
.btn-link{text-decoration:none;}
.navbar-toggler:focus{box-shadow: none;}

.mt-n2 { margin-top: -0.5rem !important; }
.mlr-10{margin-left:-10px;margin-right:-10px;}
.mb-7{margin-bottom:7px;}
.mb13{margin-bottom:13px;}
.plr10{padding-left:10px; padding-right:10px;}
.pl3{padding-left:3px;}
.pr13{padding-right:13px !important;}
.pb0{padding-bottom:0px !important;}
.py10{padding-top:11px;padding-bottom:10px;}
.semibold{font-weight:500;}
.font-normal{font-weight:400;}
.bggray{ background:#f4f4f4;}
.blue{color:#4285F4;}
.blue a{color:#4285F4;}
.blueLight{color:#4177b7;}
.blue1{color:#337ab7;}
.green{color:#32a953;}
.green a{color:#32a953;}
.stargold {color:#f8b301;}
.starblank{color:#ccc;}
.btn{cursor:pointer;}
.btn-warning{background-color:#32a953;border-color:#32a953;color:#FFF;border-radius:30px;padding:0.375rem 1.25rem;box-shadow:none !important;}
.btn-warning:hover, .btn-warning:focus, .show > .btn-warning.dropdown-toggle, .btn-warning:not(:disabled):not(.disabled):active{background-color:#258a41;border-color:#258a41;color:#FFF;}
/* .btn{box-shadow:0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);} */
.btn-danger{background-color:#d60000;}
.btn-primary{background-color:#4285F4;border-color:#4285F4;}
.btn-outline-primary {color:#4285F4; border-color:#4285F4;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {background-color:#0069d9; border-color:#0069d9;}
.btn-success{background-color:#32a953;border-color:#32a953;}
.btn-success:hover,.btn-success:focus{background-color:#00895f;border-color:#00895f;}
.graybtn .btn-outline-secondary{border-color:#ddd;color:#252525;cursor:pointer;}
.graybtn .btn-outline-secondary:hover{background-color:#fff;}
.graybtn .btn-outline-secondary:not(:disabled):not(.disabled):active,.graybtn .btn-outline-secondary:not(:disabled):not(.disabled).active,
.graybtn .show > .btn-outline-secondary.dropdown-toggle {background-color:#f2f2f2; border-color:#ddd; color:#252525;}
.arroBtn {position: relative;}
.arroBtn:after{position:absolute; right:12px; top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.text-danger{color:#f10808 !important;}
.text-info{color:#11cdef !important;}
.text-orange{color:#fb6340 !important;}
a.text-dark:hover{color:#32a953 !important; text-decoration:none;}
a.text-black-50:hover{color:#32a953 !important; text-decoration:none;}
.curpointer{ cursor:pointer;}
.tooltip-inner{background-color:#333;font-size:11px;}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before{border-top-color:#333;}

.bulletList{list-style-type:none;}
.bulletList li{padding:4px 12px; position:relative; line-height:20px;}
.bulletList li:after{ content:''; width:5px; height:5px; background:#000; position:absolute; left:0px; top:11px; border-radius:10px;}
.bulletList li a{color:#252525;}
.bulletList li a:hover{color:#f19b0f;text-decoration:none;}
.bulletList li:hover:after{background:#32a953;}

.customMinTable td{padding:5px !important;}
.customPopover{width:240px;}
.customPopover .popover-body{padding:0px !important;}

.tickList{list-style-type:none;}
.tickList li{padding:0px 5px 3px 15px; position:relative; line-height:20px;}
.tickList li .fa-check{position:absolute; left:0px; top:5px; font-size:10px;}

.spacerlist li{display:inline-block;}
.spacerlist li:before{content:'|'; padding:0 6px;}
.spacerlist li:first-child:before{display:none;}

.list5 li{margin-bottom:6px;}

.dropdown-menu{box-shadow:0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);}
.passdropdown .dropdown{display:none;}
.passdropdown .show.dropdown{display:block;}
.passdropdown .dropdown-menu{width:430px;}
.passdropdown .w-300{width:300px;}

.dropArrow:before{background:#fff; border-radius:0.2rem; bottom:100%; box-shadow:none; content:""; display:block; height:16px; left:20px; position:absolute; transform:rotate(-45deg) translateY(1rem); width:16px; z-index:-5;}
.dropArrow.dropdown-menu-end:before{left:auto;right:27px;}

.cusnav{padding:0rem 1rem 0rem; width:100%; background:#FFF;}
.cusnav .navbar-toggler{border-color:rgba(255, 255, 255, 0.2); color:#151515; padding:0.4rem 0.75rem;}
.mainNav .navbar-nav .nav-link{font-size:16px; color:#151515; padding-left:1rem; padding-right:1rem; font-weight:600;text-transform:uppercase;}
.mainNav .navbar-nav .nav-link:hover, .mainNav .navbar-nav .nav-link.active{color:#32a953;}
.cusnav .nav-link.dropdown-toggle:after{color:#FFF;}

.hmNavBtn{border-color:#32a953; color:#FFF !important; background:#32a953; width:100%; text-align:left; padding:1rem 0.75rem;font-weight:600; display:flex; justify-content:space-between;box-shadow:none !important;}

.crcDropdown{width:400px;}
.corActive .dropdown-item{position:relative; padding:0.35rem 1.5rem;}
.corActive .dropdown-item:focus, .corActive .dropdown-item.active{background-color:#f9f9f9 !important;color:#000;}
.corActive .dropdown-item.active:after{width:12px;height:11px;content:""; position:absolute; left:7px; top:8px;background:url(../images/arrow.png) left top no-repeat;} 

a.EnglishFlag:before{content:'';width:18px; height:14px; background:url(../images/IconEn.svg) no-repeat; display:inline-block;vertical-align:middle;margin-left:5px;margin-right:5px;}
a.ArabicFlag:before{content:'';width:20px; height:15px; background:url(../images/IconAr.svg) no-repeat; display:inline-block;vertical-align:middle;margin-left:5px;margin-right:5px;}
/*.flagIcon{width:18px;height:13px; display:inline-block; vertical-align:top; background:url(../images/flags.png) left top no-repeat; border:1px solid #f2f2f2; margin:3px 0 0 0px;}
.flagIcon.in{background-position:-64px -66px}
.flagIcon.ae{background-position:-16px 0;}
.flagIcon.om{background-position:-64px -110px;}
.flagIcon.qa{background-position:-32px -121px;}
.flagIcon.bh{background-position:-80px -11px;}
.flagIcon.kw{background-position:-112px -77px;}
.flagIcon.sa{background-position:-128px -121px;}
.flagIcon.pl{background-position:-176px -110px;}
.flagIcon.za{background-position:-256px -154px;}*/
.headerTop{background:#32a953;padding:3px 0px 0px;color:#FFF;}
.headerTop .container{display:flex;justify-content: space-between;align-items: center;}
.headerTop .nav > li > a{color:#FFF;}
.headerTop .nav > li > a:hover{text-decoration:none;}
.headerTop .dropdown-toggle:after{vertical-align:middle;}

.searchPanel{background:url(../images/bannerKZN.jpg) no-repeat center center /cover; /*background-attachment:fixed; background-size: 100% auto;*/ padding:200px 0 100px;}
/* .searchPanel.flightBanner{background:url(../images/bannerFlight.jpg) no-repeat center top /cover; padding-bottom:35px;}
.searchPanel.tourBanner{background:url(../images/bannerTour.jpg) no-repeat center top /cover;}
.searchPanel.transferBanner{background:url(../images/bannerTransfer.jpg) no-repeat center top /cover;}
.searchPanel.carBanner{background:url(../images/bannerCar.jpg) no-repeat center top /cover;padding-bottom:50px;} */
.searchPanel h1{text-shadow:0 0 5px rgba(0, 0, 0, 0.2);color:#FFF;line-height:48px; font-weight:700; text-align:center;font-size:36px;}
.searchPanel h1 span{font-weight:400;}
.searchColumn{background:rgba(0,0,0,0.25); border-radius:6px; padding:12px;}
.searchColumn .blueLight{color:#FFF;}
.hmform-control{font-size:14px;height:45px; background-color:#f9f9f9!important;border-color:#d7d7d7 !important;color:#151515; position:relative;background-position:calc(100% - 8px) calc(50% + 2px);}
/* .searchBtn {align-self:center;margin-top:10px;} */

button.hmform-control:after{ position:absolute; right:12px; top:50%;}
.hmform-btn{width:100%; height:50px;border-radius:30px;}


.fifthcol{width:50%; display:inline-block; vertical-align:top;}
.fifthcol:first-child .form-control, .fifthcol:first-child .form-select{border-right:0px; border-top-right-radius:0px;border-bottom-right-radius:0px; position:relative;background-color:#FFF;}
.fifthcol:nth-child(2) .form-control, .fifthcol:nth-child(2) .form-select{border-top-left-radius:0px;border-bottom-left-radius:0px;background-color:#FFF;}
.fifthcol:nth-child(2) .hmform-control{border-left:1px solid #ddd;}

.searchNav{padding:80px 0px 15px;}
.searchNav .navbar-nav li{ padding:0 4px;}
.searchNav .navbar-nav li a.nav-link{padding:0.5rem 1.6rem; display:block; font-size:14px; color:#FFF;font-weight:600; background:rgba(66,133,244,0.85);border-radius:30px;position:relative;text-align:center; line-height:21px;}
.searchNav .navbar-nav li a.nav-link:hover,.searchNav .navbar-nav li a.nav-link:focus, .searchNav .navbar-nav li a.nav-link.active{ background:#32a953;text-decoration:none;color:#FFF;}
.searchNav .navbar-nav li a.nav-link:after{pointer-events:none; position:absolute; /*z-index:-1;*/ content:''; border-style:solid; -webkit-transition-duration:0.1s; transition-duration:0.1s; -webkit-transition-property:transform; transition-property:transform; left:calc(50% - 10px); bottom:0; border-width:10px 10px 0 10px; border-color:#32a953 transparent transparent transparent; opacity:0;}
.searchNav .navbar-nav li a.nav-link:hover:after, .searchNav .navbar-nav li a.nav-link:focus:after, .searchNav .navbar-nav li a.nav-link.active:after {-webkit-transform:translateY(9.99px);transform:translateY(9.99px);opacity:1;}
.searchNav .navbar-nav li a.nav-link br{display:none;}

.menuicon{width:20px; height:16px; background:url(../images/leftsearch_icons.png) left top no-repeat; display:inline-block; vertical-align:top;}
.bg-accomdation{background-position:-10px -10px;}
.bg-apartment{background-position:-43px -10px;}
.bg-add_offline {background-position:-108px -10px;}
.bg-car_hire {background-position:-105px -10px;}
.bg-flight {background-position:-293px -10px;}
.bg-insurance {background-position:-194px -10px;}
.bg-other_services {background-position:-157px -59px;}
.bg-tour_excursion {background-position:-74px -11px;}
.bg-transfer {background-position:-136px -10px;}
.bg-visa {background-position:-164px -10px;}

.icon14{width:22px; height:20px; background:url(../images/icon1.png) left top no-repeat; display:inline-block; vertical-align:top;}
.icon14.fa{background:none;}
.bg-hotel_blue {background-position:-50px -90px;}
.bg-hotel_orange {background-position:-90px -90px;}
.bg-wifi {background-position:-90px -50px;}
.bg-hotelbell {background-position:-10px -50px;}
.bg-swimmming {background-position:-90px -10px;}
.bg-parking {background-position:-130px -48px;}
.bg-restaurants {background-position:-50px -50px;}
.bg-cup {background-position:-10px -10px;}
.bg-gym {background-position:-50px -10px;}
.bg-tv {background-position:-10px -90px;}
.bg-mapview{background-position:-130px -10px;}
.bg-share{background-position:-130px -86px;}

.icon22{width:22px; height:20px; background:url(../images/icon2.png) left top no-repeat; display:inline-block; vertical-align:top;}
.icon22.fa{background:none;}
.bg-icon_bag {background-position:-20px -2px;}
.bg-icon_door {background-position:-65px -2px;}
.bg-icon_gear {background-position:-85px -2px;}
.bg-icon_seat {background-position:2px -3px;}
.bg-icon_ac {background-position:-42px -2px;}

.active .bg-wifi {background-position:-237px -50px;}
.active .bg-hotelbell {background-position:-157px -50px;}
.active .bg-swimmming {background-position:-237px -10px;}
.active .bg-parking {background-position:-277px -48px;}
.active .bg-restaurants {background-position:-197px -50px;}
.active .bg-cup {background-position:-157px -10px;}
.active .bg-gym {background-position:-197px -10px;}
.active .bg-tv {background-position:-157px -90px;}
.nonActive .icon14{cursor:not-allowed;}

.features{background:#f1f4f2; padding:15px 0px 0px;}
.feaIcon{width:34px; height:34px; background:url(../images/feature.png) left top no-repeat; display:inline-block; vertical-align:top;}
.bg-happy{background-position:-10px -9px;}
.bg-lowest{background-position:-54px -9px;}
.bg-safe{background-position:-90px -9px;}

.boxcolhtl{margin-bottom:15px; position:relative;}
.promoDealsDiv{position:absolute;z-index:2;left:0px;right:0px;top:0px;border-radius:6px;background:linear-gradient(to top,rgba(0,0,0,0) 0,#000 100%);}
.promoDealsDivBot{position:absolute;z-index:2;left:0px;right:0px;bottom:0px;border-radius:6px;background:linear-gradient(to bottom,rgba(0,0,0,0) 0%,#000 100%);}
.promoDealsBtn{position:absolute;z-index:2;left:0px;right:0px;bottom:0px;border-radius:6px;}
.dealtxt{font-weight:500; font-size:18px; color:#fff; float:left; padding:15px;}
.promoDeals{background-color:#d60000;color:#fff;font-weight:500;border-radius:4px;text-align:center;width:58px;float:right; padding-bottom:5px; margin-right:15px; margin-top: -2px;}
.boxcolimg{border-radius:6px; width:100%;}


.topDestination{background:#FFF; padding:50px 0px;}
.topList{background:#f5fafd; padding:30px 0px 20px;}

.footerTop{ padding:25px 0px 0px; background:#eaeaea;}
.social-networks{margin:0 0 15px; padding:0;}
.social-networks li{display:inline-block;}
.social-networks a{display:block; position:relative; margin:0 2px; width:32px; height:32px; text-align:center; text-decoration:none; color:#FFF; line-height:33px;}
.social-networks a:before{content:""; position:absolute; top:50%; left:50%; width:100%; height:100%; margin-top:-50%; margin-left:-50%; border-radius:100px; background-color:#bbb; transition:all 400ms;}
.social-networks.spin-icon a:hover .fa, .social-networks.spin-icon a:focus .fa{transition:-webkit-transform 0.4s ease-out; transition:transform 0.4s ease-out; transition:transform 0.4s ease-out, -webkit-transform 0.4s ease-out; transform: rotateZ(360deg);}
.social-networks.square a:hover:before, .social-networks.square a:focus:before {border-radius:0;}
.social-networks .icon-facebook:before{background:#3b5998;}
.social-networks .icon-twitter:before{background:#00b0ed;}
.social-networks .icon-instagram:before{background:#b92685;}
.social-networks .icon-linkedin:before{background:#0077b5;}
.social-networks .icon-twitch:before{background:#d4101d;}
.social-networks a .fa{ position:relative; z-index:1}

.bgFacebook{background:#3b5998;border-color:#3b5998;}
.bgTwitter{background:#00b0ed;border-color:#00b0ed;}

.modifycol{background:#f2f2f2; border-top:1px solid #e2e2e2;border-bottom:1px solid #e2e2e2;}
.crossBtn{position:absolute; right:0px; top:0px;font-size:24px;display:none;z-index:111}
.crossBtn1{position:absolute; right:0px; top:0px;font-size:24px; z-index:111}
.leftFilter{width:295px; margin-bottom:20px;}
.leftFilter .card{border:none; border-bottom:1px solid #ddd;border-radius:0px;}
.leftFilter .card-header{padding:0.65rem 0.75rem 0.55rem; background:none; border:none; cursor:pointer; position:relative;}
.cardArrow{position:absolute; right:12px; top:12px; font-size:13px; color:#d9d9d9;}
.leftFilter .card-body{padding:0.75rem; padding-top:0px;}
.mapBtn{ bottom:0px; right:0px; position:absolute;}
.leftFilter .accordion-button{padding:0px;background:#FFF;border-radius:0px;border:none;font-weight:600;font-size:15px;color:#000;box-shadow:none;}
.leftFilter .accordion-button:after{width:14px;height:14px; background-size:14px 14px;}

.rightResult{width:100%; padding-left:1rem; padding-top:1rem;}
.htlboxcol{width:100%; background:#FFF; margin-bottom:15px; border-radius:4px; box-shadow:0 2px 5px #e8e8e8;}
.htlboxcol .border-left,.htlboxcol .border-top{border-left-color:#f2f2f2 !important;border-top-color:#f2f2f2 !important;}
.sortTop{ background:#e6eef1; padding:8px 10px 4px; margin-bottom:15px; border:1px solid #dde2e4;}
.htlboxcol:hover{box-shadow:0 0 8px #d3d0d0;}
.boxRating{font-size:15px!important;}
.hotelImg{min-width:200px;max-width:200px; overflow:hidden; position:relative; background:#f9f9f9;}
.hotelImg:after{content:'';position:absolute; left:0px;right:0px;top:0px;bottom:0px; background:-webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, #06448f 100%);background:linear-gradient(0deg, rgba(255,255,255,0) 0%, #06448f 100%);transition:all .5s ease 0s;opacity:0;z-index:1}
.hotelImg:before{content:'View Photos';position:absolute; left:0px;right:0px;bottom:100%;color:#FFF;z-index:2;text-align:center;text-shadow:0 0 5px rgba(0,0,0,0.5);transition:all .5s ease 0s;}
.hotelImg:hover:after{opacity:1;}
.hotelImg:hover:before{bottom:30%;}
.hotelImg img{width:100%;position:absolute; left:0px;top:0px; text-align:center; /*top:50%; transform:translateY(-50%);*/filter:saturate(1.1) brightness(1) contrast(1.1);}
.noView:after,.noView:before{display:none;}
.noView img{top:0%; transform:inherit;}
.mapHotelImg{min-width:150px;max-width:150px;overflow:hidden;}
.fullbox{background:#fff; box-shadow:0 0 5px rgba(0, 0, 0, 0.13); margin:20px 0 0; padding:15px 15px 10px;}

.transferImg{min-width:250px;max-width:250px; overflow:hidden; position:relative; background:#f9f9f9; min-height:110px; text-align:center;}

.roomRow{border:1px solid #f2f2f2; border-bottom:none; padding:0px 6px; transition: box-shadow 0.2s ease-in-out;}
.roomRow:hover{box-shadow:0 4px 5px rgba(50, 50, 93, 0.05), 0 1px 3px rgba(0, 0, 0, 0.05);}
.roomRow .border-left,.roomRow.border-top,.roomRow.border-bottom{border-color:#f2f2f2 !important;}

.headClose .close{position:absolute; right:7px; top:2px; background:#FFF;border-radius:100%;box-shadow:0 0 5px rgba(0,0,0,0.5); width:36px; height:36px; padding:0px;opacity:1!important;z-index:999}

.mapLeftFilter{max-width:280px;}
.mapLeftFilter .leftFilter{width:100%;box-shadow:none;}
.mapLeftFilter .mapDiv {display:none !important;}
.mapLeftFilter .mainContent{display:block !important;}

.dummyDiv{ background:#eeeeee; width:100%; height:35px; margin:0 0 15px;}
.dumwave {height:35px; text-align:center; display:inline-block}
.dumwave .anim{background-color:#dfdfdf; height:35px; width:7px; margin:0 1px 0 0; display:inline-block; -webkit-animation:waveanimation 1.2s infinite ease-in-out; animation:waveanimation 1.2s infinite ease-in-out}
.dumwave .anim1{-webkit-animation-delay:-1.2s; animation-delay: -1.2s}
.dumwave .anim2 {-webkit-animation-delay:-1.1s; animation-delay:-1.1s}
.dumwave .anim3 {-webkit-animation-delay: -1s; animation-delay: -1s}
.dumwave .anim4 {-webkit-animation-delay: -0.9s; animation-delay: -0.9s}
.dumwave .anim5  { -webkit-animation-delay: -0.8s; animation-delay: -0.8s}

.cancelLink:focus,.cancelLink:active{color:red;}

.stepNav{background:#f8f9fa; margin:0 0 15px;border-bottom:none;}
.stepNav .nav-link{padding:.8rem;color:#FFF; position:relative; text-align:center; font-weight:500; font-size:16px;border:none;flex-basis:0;flex-grow:1;display:flex;}
.stepNav .nav-link.disabled:after,.stepNav .nav-link.active:after{background:#f8f9fa;box-shadow:none;}
.stepNav .nav-link:after{content:'';position:absolute; left:0px; right:0px; top:0px; bottom:0px; background:#32a953;box-shadow:0 8px 26px -10px rgba(255, 193, 7, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 4px 10px -5px rgba(255, 193, 7, 0.2);}
.stepNav .nav-link.disabled{background:inherit;color:#000;}
/* .stepNav .nav-link.active:after{background:#fff3d0;} */
.stepNav .nav-link.active{color:#32a953;}
.stepNav .nav-link .stepTxt{position:relative; z-index:11;margin:0 auto; align-self:center;}

.arrowtab .nav-tabs{margin-bottom:10px; border-bottom:1px solid #dee2e6;}
.arrowtab .nav-tabs > li > a{border:none !important; font-size:16px; position:relative; color:#222; padding:12px 15px 10px; background:none !important;}
.arrowtab .nav-tabs > li > a:after{content:''; position:absolute; left:0px; right:0px; height:2px; background:#32a953; bottom:-1px; -webkit-transition:all 250ms ease 0s; -webkit-transform:scale(0); transition:all 250ms ease 0s; transform:scale(0); box-shadow:0 2px 3px rgba(246, 154, 45, 0.2);}
.arrowtab .nav-tabs > li > a.active,.arrowtab .nav-tabs > li > a:hover{color:#32a953; cursor:pointer;}
.arrowtab .nav-tabs > li > a.active:after,.arrowtab .nav-tabs > li > a:hover:after{-webkit-transform:scale(1); transform:scale(1);}
.arrowtab .tabTxt{position:relative;}
.arrowtab li a.active .tabTxt:after, .arrowtab li a.active .tabTxt:before {bottom:-11px; left:50%; border:solid transparent; content:" "; height:0; width:0; position:absolute; pointer-events:none;}
.arrowtab li a.active .tabTxt:after {border-bottom-color:#fafafa; border-width:6px; margin-left:-6px; z-index:1;}
.arrowtab li a.active .tabTxt:before {border-bottom-color:#32a953; border-width:9px; margin-left:-9px; z-index:1;}

.stepTick {color:#09982a; vertical-align:middle; margin-right:2px;font-size:21px;display:none;}
.active .stepTick{display:inline-block;}

.paymentTab .nav-link{color:#000; padding:15px 30px;border-bottom:1px solid rgba(0, 0, 0, 0.125);border-right:1px solid rgba(0, 0, 0, 0.125);font-size:18px;}
.paymentTab .nav-link.active{background:#FFF;border-right-color:#FFF;}
.roundedTab .nav-link{font-weight:500;cursor:pointer; background:rgba(255,255,255,0.6); margin:0px 3px; padding:0.4rem 1rem;color:#252525;}
.roundedTab .nav-link.active, .roundedTab .show > .nav-link {background:#32a953;color:#FFF;}
.updating:before{content:"";background:rgba(0,0,0,0.7);display:block;position:fixed;top:0;left:0;height:100%;width:100%;z-index:99990}

.updating .loader{display:inline-block;}
.loader {animation:rotate 1s infinite; height:50px; width:50px; display:none; left:50%; position:fixed; text-align:center; top:45%; z-index:999999; margin-left:-25px;}
.loader:before, .loader:after {border-radius:50%;  content:''; display:block; height:20px; width:20px;}
.loader:before {animation:ball1 1s infinite; background-color:#cb2025;  box-shadow:30px 0 0 #f8b334; margin-bottom:10px;}
.loader:after {animation: ball2 1s infinite; background-color:#00a096; box-shadow:30px 0 0 #97bf0d;}

.vue-slider-process{background:#32a953 !important;}
.vue-slider-dot-handle{border:1px solid #ababab;background-color:#f8f8f8 !important;}
.vue-slider-dot-tooltip-inner{background-color:#f9b300 !important;border-color:#f9b300 !important;}

.horizontal-slider {width: 100%; height:25px;}
.horizontal-slider .example-track { top:8px; height:5px;}
.example-track { position: relative; background: rgb(221, 221, 221); border-radius:.25rem;}
.example-track.example-track-1 {background:#32a953;}
.example-thumb {
    text-align: center;
    background-color:#f8f8f8;
    color: white;
    cursor: pointer;
    box-shadow:0.5px 0.5px 2px 1px rgb(0 0 0 / 32%);
    border:1px solid #ababab;
    box-sizing: border-box;
    border-radius:50%;
}
.horizontal-slider .example-thumb {
    top: 1px;
    width:18px;
    height:18px;
}

/*S*/
.mainairstop {text-align: center; font-size: 12px; position: relative; margin: 4px 0 0 0px;}
.v-facebook-login {display:inline-block !important; padding: 0.375rem 0.75rem !important; min-width:inherit !important;}
/*end S*/
@keyframes rotate {
    0% {-webkit-transform: rotate(0deg) scale(0.8); -moz-transform:rotate(0deg) scale(0.8);}
50% {-webkit-transform: rotate(360deg) scale(1.2); -moz-transform:rotate(360deg) scale(1.2);}
100% {-webkit-transform: rotate(720deg) scale(0.8); -moz-transform:rotate(720deg) scale(0.8);}
}

@keyframes ball1 {
0% {box-shadow:30px 0 0 #f8b334;}
50% {box-shadow:0 0 0 #f8b334; margin-bottom:0; -webkit-transform:translate(15px,15px); -moz-transform:translate(15px, 15px);}
100% {box-shadow:30px 0 0 #f8b334; margin-bottom:10px;}
}

@keyframes ball2 {
0% {box-shadow:30px 0 0 #97bf0d;}
50% {box-shadow:0 0 0 #97bf0d; margin-top:-20px; -webkit-transform:translate(15px,15px); -moz-transform:translate(15px, 15px);}
100% {box-shadow:30px 0 0 #97bf0d; margin-top:0;}
}

@-webkit-keyframes waveanimation {
0%, 40%, 100% {-webkit-transform:scaleY(0.3); transform:scaleY(0.3)}
20% {-webkit-transform:scaleY(1); transform:scaleY(1)}
}
@keyframes waveanimation {
0%, 40%, 100% {-webkit-transform:scaleY(0.3); transform:scaleY(0.3)}
20% {-webkit-transform:scaleY(1); transform:scaleY(1)}
}

.cellpad10 td, .cellpad10 th {padding:10px;}
.cellpad8 td, .cellpad8 th {padding:8px;}
.cellpad5 td, .cellpad5 th {padding:5px;}
.cellpad2 td, .cellpad2 th {padding:2px;}
.amentiData ul li{margin-bottom:5px; margin-left:15px;}
.amentiData p{margin-bottom:8px;}
.btn-number{border:1px solid #b3b3b3; border-radius:100%; color:#b3b3b3; display:inline-block; font-size:12px; height:30px; line-height:30px; text-align:center; width:30px; background:none; outline:none; padding:0px;box-shadow:none;}
.input-number{width:35px;height:30px; background:none !important; border:none; box-shadow:none !important; display:inline-block; color:#000; padding:0px; text-align:center !important;}

.stopline{ height:2px; background:#b6b6b6; position:relative;}
.stopline:after{content:'';position:absolute; left:0px; top:-4px; width:9px; height:9px; border:1px solid #b6b6b6; background:#FFF; border-radius:100%;}
.stopline:before{content:'';position:absolute; right:0px; top:-4px; width:9px; height:9px; border:1px solid #b6b6b6; background:#FFF; border-radius:100%;}
.duration{text-align:center; font-size:12px; margin:1px 0 5px 0px;}

.flightItemHead{background:#063978; display:inline-block; color:#FFF; font-size:13px; padding:2px 10px; border-top-left-radius:4px; position:relative; line-height:18px;}
.flightItemHead:after{left:100%; top: 0; border:solid transparent; content:" "; height:0; width:0; position:absolute; border-style:solid; border-width:22px 18px 0 0; border-color:#063978 transparent transparent transparent;}
.plusCollapse:before{content:'- Less '}
.plusCollapse.collapsed:before{content:'+ More '}

.sidebarLeft .list-group-item {padding:1rem 1.25rem; font-size:16px;}
.sidebarLeft .list-group-item.active{background-color:#f7f7f7; color:#0069d9;border-color:#dfdfdf;border-right-color:#f7f7f7;}

.insuranceBtn .btn {border-radius:50rem !important;}
.insuranceBtn .btn.active{background-color:#02ae79 !important; border-color:#02ae79 !important;}

.autocmpltBox { position:inherit;}
.autocmpltBox  .vbt-autcomplete-list {width:auto !important; max-height:inherit !important;}
.autocmpltBox .list-group-item.active {color:#000; background-color:#f8f9fa ; border-color:#dfdfdf;}

.logoHeight{height:60px;transition:all 0.2s; margin:10px 0px;}
.landing .headerMain{transition:all 0.2s;width:100%;top:0px;}
.landing .absoluteNav {position:absolute !important;}
.landing .fixedNav {box-shadow:0 2px 3px 0px rgba(64,60,67,.05); position:fixed !important; z-index:999}
.landing .fixedNav .headerTop{height:0px; padding:0px;}
.landing .fixedNav .logoHeight{height:50px;}
/* .landing #mainnavigation, .landing .mainNavBtn {display:none !important;} */
.mapFilter{display:none;}
/*#mapView .mapFilter{display:block;}*/
.navbar-brand{margin:0px !important; padding:0px;}

.circleicon {border:1px solid; border-radius:100%; display:inline-block; font-size:10px; font-style:normal; height:16px; line-height:14px; text-align:center; vertical-align:middle; width:16px;}
.nonrefund {border-color:#f40010; color:#f40010;}
.refund {border-color:#0daa44; color:#0daa44;}

.airstop {position:relative; display:inline-block; vertical-align:top; color:#e88585; margin:0 4px;font-size:11px;}
.airstop:after {content:''; position:absolute; -webkit-transform:translateX(-50%); transform:translateX(-50%); left:50%; top:-9px; width:7px; height:7px; border:1px solid #e88585; border-radius:100%; background:#FFF;}
#modifyNav{display:none;}

.fareRuleMod h2 {font-size: 14px; font-weight:700;}
.fareRuleMod p {font-size: 13px;}
.inputiconCal{height:19px;}
.inputiconCal:before {content:url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="calendar-alt" class="svg-inline--fa fa-calendar-alt fa-w-14" role="img" height="16" width="16" viewBox="0 0 448 512"><path fill="gray" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z" class=""></path></svg>');}

#flightDetails .modal-dialog-scrollable .modal-content{overflow:inherit;max-height:calc(100vh - 1rem);}
.topBtn {position:fixed; right:18px;bottom:18px;box-shadow:0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);background-color:#25d366;height:55px;width:55px;border-radius:50%;display:inline-block;color:#FFF!important;text-align:center;font-size:33px;line-height:54px;transition:all 1s;}
.pac-container:after {background-image:none !important;  height:0px;}
.collapsed > .cardArrow > .fa-minus-square{display:none;}
div[aria-expanded="true"] > .cardArrow > .fa-plus-square{display:none;}
.modalsitemap {width:100%; top:0px; padding:0 0 50px 0px; border-radius:0px; max-height:100%;  overflow-y:auto; vertical-align:top !important; margin:0px !important; height:100%; max-width:inherit;}
.modalsitemap .close{display:none;}
.modalsitemap .modal-content{border-radius:0px;}
.sitemapfot{position:fixed; bottom:0px; left:0px; right:0px; padding:10px 20px; background:#003263; border-top:1px solid #ddd; z-index:9999}

/* .mainsiteplace{border-left:2px solid #f3f3f3; border-right:2px solid #f3f3f3; padding:20px 15px 0px 20px; display:inline-block; position:relative;}
.planetop{margin:0 auto;}
.planetop img{width:100%;}
.planebot{margin:0 auto; padding-left:1px; margin-top:-25px;}
.planebot img{width:100%;}
.siteplace{position:relative; margin:0px; padding:0px; height:100%;}
.siteplace a{font-size:10px; display:inline-block; margin:8px 0 0 0px;color:#053982;}
.siteplace li{list-style:none; position:absolute; text-align:center; font-family:Arial, Helvetica, sans-serif; cursor:pointer;}
.siteplace .selected{background:url(../images/air/selected.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .selectedSeat{background:url(../images/air/selectedSeat.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .freeAvlb{background:url(../images/air/freeAvlb.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .paidAvlb{background:url(../images/air/paidAvlb.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .postReservation{background:url(../images/air/postReservation.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .blocked{background:url(../images/air/blocked.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .occupied{background:url(../images/air/occupied.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .exitRow{background:url(../images/air/exitRow.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .handicapped {background:url(../images/air/handicapped.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .exempted{background:url(../images/air/exempted.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .unAvlb{background:url(../images/air/unAvlb.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .handicappedPaidAvlb{background:url(../images/air/handicappedPaidAvlb.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .handicappedFreeAvlb{background:url(../images/air/handicappedFreeAvlb.png) left top no-repeat; height:35px; width:31px; display:block;}
.siteplace .noSeat{height:35px; width:31px; display:block;} */

.leftwing{position:absolute; left:-171px; width:170px; margin:-10px 0  -65px 0px;
background:linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(237,237,237,1) 100%);
filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ededed',GradientType=1 );}
.leftwing:after{content:''; left:0px; top:0px; right:0px; width:0; height: 0; border-top:90px solid #FFF; border-right:170px solid transparent; position:absolute;}
.leftwing:before{content:''; left:0px; bottom:0px; right:0px; width:0; height: 0; border-bottom:90px solid #FFF; border-left:170px solid transparent; position:absolute;}

.rightwing{position:absolute; right:-171px; width:170px; margin:-10px 0  -65px 0px; background:linear-gradient(to right, rgba(237,237,237,1) 0%,rgba(255,255,255,0) 100%);
filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#00ffffff',GradientType=1 );}
.rightwing:after{content:''; left:0px; top:0px; right:0px; width:0; height: 0; border-top:90px solid #FFF; border-left:170px solid transparent; position:absolute;}
.rightwing:before{content:''; left:0px; bottom:0px; right:0px; width:0; height: 0; border-bottom:90px solid #FFF; border-right:170px solid transparent; position:absolute;}

.seatNumbers{position:absolute; left:-30px; z-index:1}
.seatNumbers ul{list-style-type:none; margin:0px; padding:0px;}
.seatNumbers ul li{height:42px; line-height:39px; font-weight:600; font-size:15px;}
.userText{list-style-type:none; margin:10px 0 20px 0px; padding:0px; border-top:1px solid #d0dae0;}
.userText li a{border-bottom:1px solid #d0dae0; padding:12px 10px 12px 18px; position:relative; margin-right:-2px; font-size:15px; cursor:pointer; display:block;}
.userText li a.active{background:#f5fafd; color:#32a953; font-weight:600;}
.userText li a:hover,.userText li a:focus {text-decoration:none;}

.wingLine{position:absolute; left:-70px; border-right:1px solid #000; font-weight:600; z-index:1}
.wingLine:after{content:'';width:20px; height:1px; background:#000; display:inline-block; left:18px; top:0px; position:absolute;}
.wingLine:before{content:'';width:20px; height:1px; background:#000; display:inline-block; left:18px; bottom:0px; position:absolute;}
.wintext{transform:rotate(-90deg); display:inline-block; position:relative; top:40%; margin:0px -25px 0 0px; color:#000; background:#FFF; padding:0px 7px;}
.leftpanelLinks .list-group-item.active{background-color:#063978;border-right:1px solid#FFF;margin-right:-1px;font-weight:600}
.matrixDiv .owl-carousel .owl-item{text-align:center;border-right:1px solid #dee2e6;border-bottom:1px solid #dee2e6}
.matrixDiv .owl-carousel .owl-item img{ width:auto; display:inline-block;}
.matrixDiv [id*='carousel_prev'] {display:block !important;}
.matrixDiv [id*='carousel_next'] {display:block !important;}
.matrixDiv .prev {left:-10px; position:absolute; z-index:111; top:55%; background:#0058af; opacity:0.7; -webkit-transform:translateY(-55%); transform:translateY(-55%); width:30px; height:30px; text-align:center; font-size:16px; line-height:30px;border-radius:100%;cursor:pointer;}
.matrixDiv .next {right:-10px; position:absolute; z-index:111; top:55%; background:#0058af; opacity:0.7; -webkit-transform:translateY(-55%); transform:translateY(-55%); width:30px; height:30px; text-align:center; font-size:16px; line-height:30px;border-radius:100%;cursor:pointer;}

.innerBanner{background-color:#fff; padding:150px 10px 100px; background-color:#999; color:#fff; background-position:center center; -webkit-background-size:cover; -moz-background-size:cover; -ms-background-size:cover; -o-background-size:cover; background-size:cover; position:relative; margin-bottom:50px; text-align:center;}
.innerBanner:after {content:''; position:absolute; left:0; right:0; top:0; bottom:0; z-index:1; background:-moz-linear-gradient(45deg,rgba(68,50,129,.8) 0%,rgba(33,110,160,.8) 19%,rgba(25,119,164,.8) 57%,rgba(59,68,144,.8) 81%,rgba(78,47,135,.8) 100%); background:-webkit-linear-gradient(45deg,rgba(68,50,129,.8) 0%,rgba(33,110,160,.8) 19%,rgba(25,119,164,.8) 57%,rgba(59,68,144,.8) 81%,rgba(78,47,135,.8) 100%); background:linear-gradient(45deg,rgba(68,50,129,.8) 0%,rgba(33,110,160,.8) 19%,rgba(25,119,164,.8) 57%,rgba(59,68,144,.8) 81%,rgba(78,47,135,.8) 100%); filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc443281',endColorstr='#cc4e2f87',GradientType=1);}
.innerBannerTxt{position:relative;z-index:2;}
.innerBannerTxt a{color:#FFF;}
.innerBannerTxt a:hover{color:#f19b0f;text-decoration:none;}
.staticTxt h2{font-size:21px; margin:0 0 15px 0px;color:#0058af;}
.staticTxt h3{font-size:18px; margin:0 0 15px 0px;color:#0058af;}

.hmbg1{background:url(../images/hmbg1.jpg) no-repeat center center /cover;background-attachment:fixed; position:relative; overflow:hidden; padding:5rem 0rem 2rem;}
.hmbg1:before{content:'';position:absolute; width:100%; background-color:#eaeaea; aspect-ratio:1; border-radius:9999px; left:0; top:0; transform:translateY(-50%) translateX(-50%);}
.colImgWrap{position: relative; overflow: hidden;}
.colImgRight{position:absolute; bottom:-35px; right:-35px; width:150px; height:150px; border-radius:50%;}
.waveAnim{padding-top:45px; padding-bottom:225px; background-color:#ffffff; position:relative; overflow:hidden;}
.waveAnim svg{position:absolute; bottom:-9.5rem; left:50%; transform:translate(-50%) scaleX(-2) rotate(-12deg); filter:fliph; animation:wave2 5s ease-in-out infinite;}

@keyframes wave2 {
from {
    transform: translate(-50%) scaleX(-2) rotate(-10deg);
}
50% {
    transform: translate(-50%) scaleX(-2.2) scaleY(1.1) rotate(-13deg);
}
to {
    transform: translate(-50%) scaleX(-2) rotate(-10deg);
}
}

.hmbgGreen{background:url(../images/hmbgGreen.jpg) #32a953 no-repeat center center;background-attachment:fixed; position:relative; overflow:hidden; padding:5rem 0rem 5rem; color:#FFF;}
.hmbgGray{background:url(../images/hmbgGray.jpg) no-repeat left center /cover; position:relative; overflow:hidden; padding:5rem 0rem 5rem;}
.circleDiv{border-radius:50%; background:#FFF; padding:10px; width:500px; height:500px; margin:auto; display:flex; align-items:center; flex-direction:column;justify-content:center; text-align:center;}
.hmbgPromo{background:url(../images/hmbgPromotion.jpg) #FFF no-repeat right bottom; position:relative; padding:5rem 0rem 5rem;}
.hmbgAbt{background:url(../images/hmbgAbt.jpg) no-repeat center center /cover;background-attachment:fixed; position:relative; overflow:hidden; padding:5rem 0rem 5rem;}
.hmbgAbt:after{content:'';position:absolute;width:100%; background-color:#eaeaea; aspect-ratio:1; border-radius:9999px; left:0; bottom:0; transform:translateY(50%) translateX(-50%);}
.hmbgAbt:before{content:'';position:absolute;width:100%; background-color:#eaeaea; aspect-ratio:1; border-radius:9999px; right:0; top:0; transform:translateY(-50%) translateX(50%);}
#modifyText{display:flex; justify-content:space-between;align-items: center; padding:10px 0px;}

.react-datepicker-popper{z-index:100 !important;}
.react-datepicker__day--outside-month {visibility:hidden !important; color:transparent !important; pointer-events:none; background-color:transparent !important;}
.designCalendar .react-datepicker__header{border:none; background-color:#FFF;}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{background-color:#32a953 !important;}
.designCalendar .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .designCalendar .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{border-bottom-color:#FFF}
.designCalendar .react-datepicker__day-name, .designCalendar .react-datepicker__day, .designCalendar .react-datepicker__time-name{width:2rem;line-height:2rem;}
.designCalendar  .react-datepicker__current-month, .designCalendar  .react-datepicker-time__header, .designCalendar  .react-datepicker-year-header{padding:3px 0px;}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{border-color:#32a953 !important;width:12px !important;height:12px !important;}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{background-color:transparent !important;color:#000 !important;}
.input-group>.react-datepicker-wrapper {position: relative; flex: 1 1 auto; width: 1%; min-width: 0;}

.typeHeadDropdown .dropdown-item{font-size:14px;border-bottom:1px solid rgba(0,0,0,.125);padding:.60rem 1rem;}
.svg1{fill: none; stroke: none;}
.svg2{fill:#eaeaea;stroke:black;stroke-width:1px;}
/* vector-effect:non-scaling-stroke; */
.svg3{stroke:black;stroke-width:1px;fill:#eaeaea;}
.svg4{stroke:black;fill:#eaeaea;stroke-width:0px;}
.svg5{stroke:black;fill:#2ea750;stroke-width:0px;}

.image-gallery-content .image-gallery-slide .image-gallery-image{max-height:calc(60vh - 80px) !important;}
.image-gallery-thumbnail .image-gallery-thumbnail-image{width:auto !important; height:70px !important;}
.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {max-height: calc(100vh - 80px) !important;}
.image-gallery-left-nav svg, .image-gallery-right-nav svg{width:30px !important; height:60px !important;}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{border-color:#2ea750 !important;}

.PhoneInputInput{border:none;}
.btnPlayVdo{position:absolute; left:46%; top:45%; background:none !important; padding:0px; border:none !important; box-shadow: none !important; z-index:1;}
.slow-spin {-webkit-animation:fa-spin 1s infinite linear; animation:fa-spin 1s infinite linear;}
@keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

/*For Arabic Start*/
.dirLtr{direction:ltr;}
.arClass .ardirLtr{direction:ltr !important;}
.arClass{font-family:'Roboto',"Tahoma",BlinkMacSystemFont,-apple-system,Segoe UI,Helvetica,Arial,sans-serif!important; text-align:right;}
/* .arClass .form-control{direction:ltr;} */
.arClass .calinput{direction:rtl;}
.arClass .form-control, .arClass .dropdown-menu{text-align:right;}
.arClass .flipDiv{transform:scaleX(-1);}
.arClass .ar-ml-auto{margin-left:auto;margin-right:inherit !important;}
.arClass .float-right{float:left !important;}
.arClass .float-left{float:right !important;}
.arClass .border-left{border-right:1px solid #dee2e6 !important;border-left:inherit !important;}
.arClass .border-right{border-left:1px solid #dee2e6 !important;border-right:inherit !important;}
.arClass .text-end {text-align:left !important;}
.arClass .text-start {text-align:right !important;}
.arClass .ar-text-left{text-align:left !important;}
.arClass .ar-pl-2{padding-left:0px!important;padding-right:0.5rem !important;}
.arClass .ar-pl-3{padding-left:0px!important;padding-right:1rem !important;}
.arClass .ar-mr-2{margin-left:0.5rem !important;margin-right:0px !important;}
.arClass .arroBtn:after {left:12px; right:inherit;}
.arClass .list-inline-item:not(:last-child) {margin-left: 0.5rem; margin-right:0rem;}
.arClass .custom-control{padding-right:1.5rem; padding-left:0px;}
.arClass .custom-control-label:before{right:-1.5rem;left:inherit;}
.arClass .custom-control-label:after{right:-1.5rem;left:inherit;}
.arClass .passdropdown .mr-auto{margin-left:auto !important; margin-right:0px !important;}
.arClass .arPl13{padding-left:13px;}
.arClass .fifthcol:first-child .form-control{border-top-right-radius:4px;border-bottom-right-radius:4px; border-top-left-radius:0px;border-bottom-left-radius:0px;}
.arClass .fifthcol:nth-child(2) .form-control{border-top-left-radius:4px;border-bottom-left-radius:4px; border-top-right-radius:0px;border-bottom-right-radius:0px;}
.arClass .fifthcol:nth-child(2) .hmform-control{border-right:1px solid #ddd; border-left:none;}
.arClass .bulletList li:after{ left:inherit; right:0px;}


.arClass .features .feaIcon{margin-left:1rem;margin-right:0px !important;}
.arClass .dealtxt{float:right;}
.arClass .promoDeals{float:left;}
.arClass .headClose .close{left:-5px;right:inherit;}
.arClass .rightResult{padding-right:1rem; padding-left:0px;}
.arClass .hotelImg.rounded-left{border-top-left-radius:0rem !important;border-bottom-left-radius:0rem !important;border-top-right-radius:0.25rem !important;border-bottom-right-radius:0.25rem !important;}
.arClass .cardArrow{left:12px;right:inherit;}
.arClass .htlright {padding-left:0.25rem !important; padding-right:1rem !important;}
.arClass .input-group > .input-group-append > .btn, .arClass .input-group > .input-group-append > .input-group-text, .arClass .input-group > .input-group-prepend:not(:first-child) > .btn, .arClass .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .arClass .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .arClass .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {border-top-left-radius:0.25rem;  border-bottom-left-radius:0.25rem; border-top-right-radius:0;  border-bottom-right-radius:0;}
.arClass .input-group > .form-control:not(:last-child), .arClass .input-group > .custom-select:not(:last-child) {border-top-right-radius:0.25rem; border-bottom-right-radius:0.25rem; border-top-left-radius: 0; border-bottom-left-radius: 0;}
.arClass .input-group-append {margin-left:0px;margin-right:-1px;}
/* .arClass .btn-group > .btn:first-child{border-top-right-radius:0.25rem; border-bottom-right-radius:0.25rem; border-top-left-radius:0; border-bottom-left-radius:0;}
.arClass .btn-group > .btn:last-child{border-top-left-radius:0.25rem; border-bottom-left-radius:0.25rem; border-top-right-radius:0; border-bottom-right-radius:0;}
.arClass .btn-group > .btn:not(:first-child), .arClass .btn-group > .btn-group:not(:first-child) {margin-left:0px; margin-right:-1px;} */
.arClass .modal-header .close{margin-right:auto; margin-left:-1rem;}
.arClass .tickList li{padding-right:18px; padding-left:5px;}
.arClass .tickList li .fa-check{left:inherit; right:0px;}
/* .arClass .resFixedBtn .align-middle[data-icon="long-arrow-alt-right"],.arClass .resFixedBtn .align-middle[data-icon="long-arrow-alt-left"] {transform:scaleX(-1);} */
.arClass .paymentTab .nav-link{border-right:none;border-left:1px solid rgba(0, 0, 0, 0.125);}
.arClass .paymentTab .nav-link.active{border-left-color:#FFF;}
.arClass .crossBtn{left:0px;right:inherit;}
.arClass button.hmform-control:after{left:12px;right:inherit;}
.arClass .flightItemHead{border-top-right-radius:4px;border-top-left-radius:0px;}
.arClass .flightItemHead:after{border-color:transparent transparent #063978 transparent;border-width:0 0 22px 18px; right:100%; left:inherit;}
.arClass .arrowtab .fa-plane{transform:scaleX(-1);}
.arClass .corActive .dropdown-item.active:after{right:7px;left:inherit;}
.arClass .vdp-datepicker__calendar:after{right:25px;left:inherit;}
.arClass .mr-auto{margin-left:auto !important;margin-right:inherit !important;}
.arClass .crossBtn1{left:0px;right:inherit;}
.arClass .fa-long-arrow-alt-right{ transform: rotateZ(-180deg);}
.arClass .fa-long-arrow-alt-left{ transform: rotateZ(-180deg);}

@media (min-width: 992px) {
.arClass .float-lg-right {float:left !important;}
.arClass .float-lg-left {float:right !important;}
}
@media (min-width: 768px) {
.arClass .ar-offset-md-1{margin-left:0;margin-right:8.333333%;}
}
@media (min-width: 576px){
.arClass .float-sm-right {float:left !important;}
.arClass .float-sm-left {float:right !important;}
.arClass .text-sm-right {text-align:left !important;}
.arClass .text-sm-left {text-align:right !important;}
.arClass .arhotelItenDetail{padding-right:1rem !important;padding-left:0.25rem !important;}
}

/*For Arabic end*/
@media print and (color) {
* {-webkit-print-color-adjust:exact !important; print-color-adjust:exact !important;}
.noneDiv {display:none;}
}

@media (min-width:1300px) {
.container{max-width:1299px;}
}
@media (max-width:1299px) {
.container{width:100%; max-width:inherit;}
}
@media (min-width:960px){
#leftCollapse.collapse/*,#modifyCollapse.collapse*/{display:block !important;} 
.mt-lg-12{margin-top:-12px;}
.w-lg-100{width:100%;}
.w-lg-50{width:50%;}
.acNav{display:flex !important;}
}
@media (min-width:767px){
.doublelist{display:flex; flex-wrap:wrap;}
.doublelist li{flex:0 0 50%; max-width:50%;}
}

@media only screen and (min-width: 240px) and (max-width:960px) {
html, body{background:#FFF;}
.landing .cusnav, .cusnav {padding-left:0px;padding-right:0px;top:0px; z-index:111}
.rightResult{ padding-left:0px; padding-top:0px;}
.leftFilter{ width:100%;position:static !important; margin-bottom:10px;}
/* .dropdown-menu-right{left:0; right:auto;} */
.dropArrow.dropdown-menu-end:before{left:20px; right:auto;}
.mapLeftFilter,.mapRight{width:100%;max-width:inherit;flex-basis:inherit;}
/* .searchPanel{background:none !important;padding-top:75px;padding-bottom:0px !important;} */
/* .searchPanel h1{color:#252525;text-shadow:none;font-size:24px; line-height:27px;margin-bottom:0px;} */
/* .searchPanel .title .fn18{font-size:13px;} */
/* .searchColumn{box-shadow:none;background:none;margin-top:10px;padding:12px 15px}
.searchColumn .blueLight{color:#252525;} */
/* .topList,.footerTop,.features{display:none;} */
.mainNav .navbar-nav .nav-link{border-top:1px solid #f2f2f2;}
.acNav{position:fixed; top:60px; right:15px;background:#FFF; z-index:111;}
.acNav .nav{display:block;box-shadow:0px 2px 7px 0px rgba(0, 0, 0, 0.25);z-index:222;}
.acNav .nav .nav-link{border-bottom:1px solid #f2f2f2;padding:0.7rem 1rem;}
.arClass .acNav{left:15px; right:inherit;}
.myacBtn.dropdown-toggle{background:none!important;border:none;box-shadow:none!important;font-size:14px;padding:0.7rem 1rem;color:#000!important;}
.acNav .nav .text-white{color:#252525 !important;}
.hotelImg,.transferImg{max-width:120px; min-width:120px;}
.crossBtn{display:inline-block;}
#modifyCollapse{padding:25px 0 0 0px;position:relative;}
.fullbox{padding-left:0px;padding-right:0px;box-shadow:none;}
.shadow-lg-none{box-shadow:none !important;}
.plrlg0{padding-left:0px;padding-right:0px;}
#modifyNav{display:block;}
/* .resFixedBtn{position:fixed; bottom:0px; left:0px; right:0px; background:#FFF; z-index:999;box-shadow:0 2px 5px #333;} */
body.modal-open {position:fixed;}
/* .topBtn{display:none !important;} */
.modal-body .mapFilter{display:block;}
#modifyText{display:none !important;}
/*#mapView{overflow-y:hidden;padding:0px !important;}
#mapView .modal-xxl{width:100%;max-width:100%;margin:0px;top:120px;}
#mapView .modal-content{height:100%;border:none;}
#mapView___BV_modal_outer_ .modal-backdrop{display:none;}
#mapView .modal-dialog-scrollable .modal-body{overflow-y:hidden;padding:0px;}
#mapView .close{display:none;}*/
}
@media only screen and (min-width: 240px) and (max-width:767px) {
/*.mainNav a{ border-bottom:1px solid rgba(255,255,255,0.5);}
.searchNav{padding-top:0px;}
.searchNav .navbar-nav li a.nav-link{border-radius:0px; border-bottom:1px solid #06448f;}
.searchNav .navbar-nav .menuicon,.searchNav .navbar-nav li a:after,.searchNav .navbar-nav li a br{display:none;}
.sticky-top{ position:static;}*/
.searchNav .navbar-nav li a.nav-link{border-radius:0px; border-bottom:1px solid #000;}
.searchNav .navbar-nav .menuicon,.searchNav .navbar-nav li a:after,.searchNav .navbar-nav li a br{display:none;}
.roomRow .border-left{border-left:none !important;}
.hotelImg img{position:inherit;transform:inherit;top:inherit;}
.md-group{display:block; flex-direction:column;}
.shadow-md-none{box-shadow:none !important;}
.htlboxcol .border-left.col-md-2{border-left:none !important;border-right:none !important;}
.airlineName{width:65px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.rightResult .pagination{display: block; text-align:center;}
.rightResult .pagination li{display:inline-block;}
}

@media only screen and (min-width: 240px) and (max-width:567px) {
.passdropdown .dropdown-menu, .crcDropdown{width:auto;}
/* .nav-link{padding:0.5rem 0.5rem;} */
.stepNav .nav-link{font-size:13px;}
.paymentTab{flex-direction:inherit !important;}
.paymentTab .nav-item{width:50%;}
.duration{font-size:11px;}
.navbar-brand{max-width:240px;}
.shadow-sm-none{box-shadow:none !important;}
.plrsm0{padding-left:0px;padding-right:0px;}
.circleDiv{width:310px; height:310px;}
.headerTop .container{display:block;}
}
@media only screen and (min-width: 240px) and (max-width:359px) {
.navbar-brand{max-width:200px;}
}


 